const blobToString = async (blob: File) => {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.readAsBinaryString(blob)
    reader.onload = function () {
      const ret = (reader.result as string).split('')
        .map((v: string) => v.charCodeAt(0))
        .map((v: number) => v.toString(16).toUpperCase())
        .map((v: string) => v.padStart(2, '0'))
        .join('')
      resolve(ret)
    }
  })
}
const isExcel = async (file: File) => {
  
  if (file) {
    const len = file.name.split('.').length
    const name = file.name.split('.')[len - 1]
    if (name !== 'xlsx' && name !== 'xls') {
      return false
    }
  }
  // 16进制的转换
  const result = await blobToString((file as any).slice(0, 8))
  const flag = (result === 'D0CF11E0A1B11AE1') || (result === '504B030414000600') || (result === '504B030414000808')
  return flag
}
export default isExcel
