
import { defineComponent, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { message } from 'ant-design-vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import Upload from '@/views/LocalRegistration/components/Upload.vue';
import { downloadFromStream } from '@/utils/payment/downloadFile';
import { downloadTemplate, uploadRestrictCity } from '@/API/localRegistration';
import isExcel from '@/utils/closing/isExcel';
import YearPicker from '@/views/Payment/components/YearPicker.vue';

export default defineComponent({
    components: {
        ExclamationCircleOutlined,
        Upload,
        YearPicker,
    },
    setup() {
        const store = useStore();

        const uploadYear = ref<string>('2022');

        const columns = [
            {
                title: 'Dealer Code',
                dataIndex: 'dealerCode',
            },
            {
                title: 'Dealer Name',
                dataIndex: 'dealerName',
            },
            {
                title: '限牌城市曲线选择',
                dataIndex: 'restrictedCity',
            },
            {
                title: 'Year',
                dataIndex: 'year',
            },
            {
                title: 'Failed Reason',
                slots: { customRender: 'FailedReason' },
            },
        ];

        const tableData = computed(
            () => store.state.localRegistration.curveSelectionUploadError
        );

        const visibleUploadDrawer = ref(false);

        const handleToUpload = () => {
            visibleUploadDrawer.value = true;
        };

        const handleDownloadTemplate = async () => {
            const data = await downloadTemplate();
            downloadFromStream(data);
        };

        const handleUpload = async (
            file: File,
            resolve: (value: unknown) => void,
            reject: (reason?: unknown) => void,
            options: any
        ) => {
            const _formData = new FormData();
            const fileName: string = file.name?.split('.')[0];
            _formData.append('file', file);
            try {
                const fileIsExcel = await isExcel(file);
                if (!fileIsExcel) {
                    message.error('file type error');
                    throw 'file type error';
                }
                const data = await uploadRestrictCity(
                    _formData,
                    uploadYear.value,
                    fileName,
                    {
                        onUploadProgress: (progressEvent: any) => {
                            if (progressEvent.lengthComputable) {
                                const percent =
                                    ((progressEvent.loaded /
                                        progressEvent.total) *
                                        100) |
                                    0;
                                options.percent.value = percent;
                            }
                        },
                    }
                );
                if (data.length === 0) {
                    return message.success('Upload Success.');
                } else {
                    store.commit(
                        'localRegistration/updateCurveSelectionUploadError',
                        data
                    );
                }
            } catch (error) {
                reject(error);
            }
            visibleUploadDrawer.value = false;
        };

        return {
            columns,
            tableData,
            visibleUploadDrawer,
            handleToUpload,
            handleDownloadTemplate,
            handleUpload,
            uploadYear,
        };
    },
});
